// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-berenson-advisory-index-js": () => import("./../../../src/pages/berenson-advisory/index.js" /* webpackChunkName: "component---src-pages-berenson-advisory-index-js" */),
  "component---src-pages-berenson-advisory-results-js": () => import("./../../../src/pages/berenson-advisory/results.js" /* webpackChunkName: "component---src-pages-berenson-advisory-results-js" */),
  "component---src-pages-berenson-capital-index-js": () => import("./../../../src/pages/berenson-capital/index.js" /* webpackChunkName: "component---src-pages-berenson-capital-index-js" */),
  "component---src-pages-berenson-capital-operating-executives-js": () => import("./../../../src/pages/berenson-capital/operating-executives.js" /* webpackChunkName: "component---src-pages-berenson-capital-operating-executives-js" */),
  "component---src-pages-berenson-capital-portfolio-js": () => import("./../../../src/pages/berenson-capital/portfolio.js" /* webpackChunkName: "component---src-pages-berenson-capital-portfolio-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-templates-news-article-js": () => import("./../../../src/templates/news-article.js" /* webpackChunkName: "component---src-templates-news-article-js" */)
}

